import * as React from 'react'
import styled from 'styled-components'

import { Section } from '@slipway/theme-components'
import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Paginator from '../components/Navigation.Paginator'

import SpecialistsHero from '../sections/specialists/Specialists.Hero'
import SpecialistsList from '../sections/specialists/Specialists.List'

import { Template, ISpecialist } from '../types'

const SpecialistsPage: Template = ({ location, pageContext }) => {
  const specialists = pageContext.group as ISpecialist[]

  return (
    <Layout
      location={location}
      basePath={pageContext.basePath}
      locale={pageContext.locale}
      languages={pageContext.languages}
      translations={pageContext.translations}
    >
      <SEO pathname={location.pathname} />
      <Section>
        <SpecialistsHero state={pageContext.state} />
        <SpecialistsList specialists={specialists} />
        <SpecialistsPaginator show={pageContext.pageCount > 1}>
          <Paginator {...(pageContext as any)} />
        </SpecialistsPaginator>
      </Section>
      <SpecialistsGradient />
    </Layout>
  )
}

export default SpecialistsPage

const SpecialistsGradient = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 590px;
  z-index: 0;
  pointer-events: none;
  background: ${(p) => p.theme.colors.gradient};
  transition: ${(p) => p.theme.colorModeTransition};
`

const SpecialistsPaginator = styled.div<{ show: boolean }>`
  ${(p) => p.show && `margin-top: 95px;`}
`
